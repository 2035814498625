import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_ENDPOINT;

class StreetService {
  async getAllStreets(id) {
    return fetch(`${apiEndpoint}/getAllStreets/?boardId=${id}`)
      .then((r) => r.json())
      .then((r) => r);
  }

  async getStreetById(id) {
    return fetch(`${apiEndpoint}/getStreetById/?streetId=${id}`)
      .then((r) => r.json())
      .then((r) => r);
  }

  async updateStreetActive(id, active) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/update/street/active`,
        { id, active },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error updating street active:', error);
      return error;
    }
  }

  async updateStreetHouses(houses, id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/update/street/houses`,
        { houses, id },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error updating street houses:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async updateStreetSold(owner, sold, id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/update/street/sold`,
        {
          owner,
          sold,
          id,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error updating street sold:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async updateStreetData(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/update/street/data`,
        data,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error updating street:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async addStreet(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/add/street`,
        data,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error adding street:', error);
      throw error; // Rethrow the error for further handling
    }
  }
}

export default new StreetService();

import { QrReader } from 'react-qr-reader';
import React, { useEffect, useRef, useState } from 'react';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import fontawesome from '@fortawesome/fontawesome';

const Viewer = (
  <div className="qr-scanner-viewer">
    <div className="qr-scanner-corner" />
    <div className="qr-scanner-corner" />
    <div className="qr-scanner-corner" />
    <div className="qr-scanner-corner" />
  </div>
);

function QrScanner() {
  fontawesome.library.add(faArrowLeft);
  const [data, setData] = useState();
  const [showQrScanner, setShowQrScanner] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    if (data) {
      setShowQrScanner(false);
      window.location.replace(data);
    }
  }, [data]);

  return (
    <div className="qr-scanner-container">
      <div className="qr-scanner-text-container">
        <h2 style={{ color: '#ffffff' }}>Scan de QR code</h2>
        <p>Scan de QR code van de straat waar je nu naartoe gaat</p>
      </div>
      <div
        className="qr-scanner"
      >
        {showQrScanner
          && (
            <QrReader
              constraints={{
                facingMode: 'environment',
                aspectRatio: { ideal: 1 },
              }}
              // eslint-disable-next-line react/no-unstable-nested-components
              ViewFinder={() => Viewer}
              onResult={(result, error) => {
                if (result) {
                  setData(result?.text);
                }

                if (error) {
                  // console.info(error);
                }
              }}
              videoContainerStyle={{ borderRadius: '20px' }}
              ref={ref}
            />
          )}
      </div>
      {/* <button className="button-46" onClick={() => {closeCam(); }}>
                    <FontAwesomeIcon style={{paddingRight: "10px"}} icon="fa-solid fa-arrow-left" />
                    Terug naar board
                </button> */}
    </div>
  );
}

export default QrScanner;

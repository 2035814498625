import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logOut from '../../helpers/authHelpers';
import { Context } from '../../contexts/userContext';

function Admin() {
  const navigate = useNavigate();
  const { userdata } = useContext(Context);

  return (
    <div className="street-card-container">
      <h2 style={{ paddingTop: '80px', margin: 0, color: '#6B7AC9' }}>Admin</h2>

      <div className="admin-container">
        <div className="admin-button-container" onClick={() => navigate('/buy-street')}>
          <div className="admin-button">
            <p>Straat gekocht</p>
          </div>
        </div>
        <div className="admin-button-container" onClick={() => navigate('/buy-house')}>
          <div className="admin-button">
            <p>Huis gekocht</p>
          </div>
        </div>
        {userdata.admin === 1
        && (
        <>
          <div className="admin-button-container" onClick={() => navigate('/update-active-streets')}>
            <div className="admin-button">
              <p>Actieve straten aanpassen</p>
            </div>
          </div>
          <div className="admin-button-container" onClick={() => navigate('/update-street')}>
            <div className="admin-button">
              <p>Straat aanpassen</p>
            </div>
          </div>
          <div className="admin-button-container" onClick={() => navigate('/add-street')}>
            <div className="admin-button">
              <p>Straat toevoegen</p>
            </div>
          </div>
          <div className="admin-button-container" onClick={() => navigate('/reset-board')}>
            <div className="admin-button">
              <p>Reset het spel</p>
            </div>
          </div>
          <div className="admin-button-container" onClick={() => navigate('/download-streets')}>
            <div className="admin-button">
              <p>Downloads</p>
            </div>
          </div>
        </>
        )}

        <div
          onClick={() => { logOut(navigate, 'U bent nu uitgelogd.'); }}
          className=""
          style={{
            position: 'absolute', top: '10px', right: '10px', color: '#6B7AC9',
          }}
        >
          <p style={{ display: 'flex', alignItems: 'center', color: '#6B7AC9' }}>
            <strong>Log uit</strong>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: '25px', height: '25px', color: '#6B7AC9', paddingLeft: '10px',
              }}
              viewBox="0 0 512 512"
            >
              <path fill="#6B7AC9" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
            </svg>
          </p>
        </div>

      </div>
    </div>
  );
}

export default Admin;

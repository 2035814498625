import React, { useContext, useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import QrDoc from '../../helpers/qrDoc';
import './spinner.css';
import ProofOfOwnerDoc from '../../helpers/proofOfOwnerDoc';
import { BContext } from '../../contexts/boardContext';
import ProofOfRouteDoc from '../../helpers/proofOfRouteDoc';

function DownloadStreets() {
  const [loader, setLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(true);
  const [pdfLoader2, setPdfLoader2] = useState(true);
  const [pdfLoader3, setPdfLoader3] = useState(true);
  const [districts, setDistricts] = useState();
  const [streets, setStreets] = useState();
  const { board, boardDataIsLoading } = useContext(BContext);

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    if (!boardDataIsLoading) {
      setDistricts(board.districts);
      const activeStreets = board.streets?.filter((s) => s.active === 1);
      setStreets(activeStreets);
    }
  }, [boardDataIsLoading]);

  if (loader || boardDataIsLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="form-container">
        <h2>Download de straten</h2>
        <BackButton url="/admin" />
        <div className="container" style={{ flexDirection: 'column' }}>
          {(pdfLoader) && (
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          )}
          <div className="button-container" style={{ marginTop: '20px' }}>
            <button disabled={pdfLoader} type="submit">
              <PDFDownloadLink
                style={{ textDecoration: 'none' }}
                document={(
                  <QrDoc setPdfLoader={setPdfLoader} districts={districts} />
              )}
                fileName={`streets_${Date.now()}.pdf`}
              >
                {() => <div className="admin-button">Downloaden</div>}
              </PDFDownloadLink>
            </button>
          </div>
        </div>
        <h2 style={{ marginTop: '70px' }}>Download de eigendomsbewijzen</h2>
        <div className="container" style={{ flexDirection: 'column' }}>
          {(pdfLoader2) && (
            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          )}
          <div className="button-container" style={{ marginTop: '20px' }}>
            <button disabled={pdfLoader2} type="submit">
              {districts && streets
                && (
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  document={(
                    <ProofOfOwnerDoc
                      setPdfLoader={setPdfLoader2}
                      districts={districts}
                      streets={streets}
                    />
              )}
                  fileName={`proof_of_owner_${Date.now()}.pdf`}
                >
                  {() => <div className="admin-button">Downloaden</div>}
                </PDFDownloadLink>
                )}

            </button>
          </div>
        </div>
        <h2 style={{ marginTop: '70px' }}>Download de routebewijzen</h2>
        <div className="container" style={{ flexDirection: 'column' }}>
          {(pdfLoader3) && (
            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          )}
          <div className="button-container" style={{ marginTop: '20px' }}>
            <button disabled={pdfLoader3} type="submit">
              {districts && streets
                && (
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  document={(
                    <ProofOfRouteDoc
                      setPdfLoader={setPdfLoader3}
                      districts={districts}
                      streets={streets}
                    />
              )}
                  fileName={`proof_of_route_${Date.now()}.pdf`}
                >
                  {() => <div className="admin-button">Downloaden</div>}
                </PDFDownloadLink>
                )}

            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default DownloadStreets;

import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_ENDPOINT;

class BoardService {
  async getBoardById(id) {
    try {
      const response = await axios.get(`${apiEndpoint}/getBoardById/?boardId=${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error fetching board by ID:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async resetBoardById(id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/resetBoardById/?boardId=${id}`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response;
    } catch (error) {
      // Handle error
      console.error('Error resetting board by ID:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async getAllDistricts(id) {
    try {
      const response = await axios.get(`${apiEndpoint}/getAllDistricts/?boardId=${id}`);
      return response;
    } catch (error) {
      // Handle error
      console.error('Error fetching board by ID:', error);
      throw error; // Rethrow the error for further handling
    }
  }

  async getQrcodes(id) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/getQrcodes/?boardId=${id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error fetching Qrcodes:', error);
      throw error; // Rethrow the error for further handling
    }
  }
}

export default new BoardService();

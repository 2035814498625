import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import HomeIcon from '../../components/homeIcon';
import { BContext } from '../../contexts/boardContext';
import StreetService from '../../services/StreetService';

function BuyHouse() {
  const navigate = useNavigate();

  const { boardDataIsLoading, board, renderBoardData } = useContext(BContext);

  const [formData, setFormData] = useState({ streetId: '', houses: '' });
  const [streetArray, setStreetArray] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    // getAllStreets();
  }, []);

  const handleHouseChange = () => {
    navigate(`/street/?streetId=${formData.streetId}`);
  };

  const validateForm = () => {
    if (formData.sold && formData.owner.length < 1) {
      setError('Als de straat verkocht is moet er een eigenaar zijn ingevuld');
      setLoading(false);
      return false;
    } if (formData.streetId < 1) {
      setError('Straat is verplicht');
      setLoading(false);
      return false;
    }
    setLoading(false);
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      await StreetService.updateStreetHouses(formData.houses, formData.streetId)
        .then(() => { handleHouseChange(); renderBoardData(); })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      const filteredStreets = board.streets.filter((item) => item.active === 1);
      setStreetArray(filteredStreets);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  const getCurrentAmountOfHouses = (id) => {
    if (id) {
      const street = streetArray?.find((item) => `${item.id}` === id);
      return street.houses;
    }
    return false;
  };

  const getHouses = (id) => {
    if (id) {
      const houses = [];
      for (let i = 1; i < 5; i += 1) {
        houses.push(<HomeIcon
          width="50px"
          backgroundColor="#D5E0FF"
          color={i <= formData.houses ? '#6B7AC9' : '#f0f2fa'}
          onClick={() => {
            if (i <= formData.houses) {
              setFormData(() => ({ ...formData, houses: formData.houses - 1 }));
            } else { setFormData(() => ({ ...formData, houses: formData.houses + 1 })); }
          }}
        />);
      }
      return houses;
    }
    return '';
  };

  const getStreetSold = (id) => {
    if (id) {
      const street = streetArray?.find((item) => `${item.id}` === id);
      return street?.sold === 1;
    }
    return 'geen';
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="form-container">
        <h2>Verkoop van een huis</h2>
        <BackButton url="/admin" />
        <form onSubmit={handleSubmit}>

          <label htmlFor="streetID">Voor welke straat wordt er een huis ver/gekocht?</label>
          <select id="streetId" name="streetId" onChange={(e) => setFormData(() => ({ ...formData, streetId: e.target.value, houses: streetArray?.find((item) => `${item.id}` === e.target.value).houses }))}>
            <option disabled selected value> -- Kies een straat -- </option>
            {streetArray?.map((item) => (
              <option value={item.id}>
                {item.name.replace(/\\n/g, '')}
              </option>
            ))}
          </select>

          {formData.streetId && getStreetSold(formData.streetId)
            ? (
              <>
                <label>{`Er staan nu ${getCurrentAmountOfHouses(formData.streetId)} huizen op deze straat`}</label>
                <label htmlFor="number">Hoeveel huizen (totaal) krijgt deze straat?</label>
                <input type="number" id="houses" max={4} value={formData.houses} onChange={(e) => setFormData(() => ({ ...formData, houses: e.target.value }))} />
                <div style={{
                  width: '100%', display: 'grid', alignItems: 'center', justifyContent: 'center', gridTemplateColumns: 'repeat(4, 1fr)', paddingTop: '10px',
                }}
                >
                  {getHouses(formData.streetId)}
                </div>
              </>
            )
            : (
              <label>
                Deze straat is nog niet verkocht, er kunnen dus geen huizen op gebouwd worden.
              </label>
            )}

          {error && <div className="form-error"><p>{error}</p></div>}
          <div className="button-container">
            <button disabled={!getStreetSold(formData.streetId)} type="submit">Opslaan</button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default BuyHouse;

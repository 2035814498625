import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/backButton';
import { Context } from '../contexts/userContext';
import AuthService from '../services/AuthService';

function Login() {
  const navigate = useNavigate();
  const { userdata, setUser } = useContext(Context);
  const [data, setData] = useState({ username: '', password: '' });
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const token = localStorage.getItem('access-token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await AuthService.login(data.username, data.password)
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
        return r.json().then((response) => { throw response.error; });
      })
      .then((r) => {
        setError('');
        localStorage.setItem('access-token', r.accessToken);
        localStorage.setItem('refresh-token', r.refreshToken);
        setUser({ username: r.user.username, loggedIn: true, admin: r.user.admin });
        navigate('/admin');
      })
      .catch((err) => { setError(err); });
  };

  useEffect(() => {
    // check if not already logged in
    if (userdata.loggedIn && token) {
      navigate('/admin');
    }
  }, []);

  return (
    <div className="street-card-container">
      <div className="form-container">
        <h2>Login</h2>
        <BackButton url="/" />
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">
            Gebruikersnaam
            <input type="text" id="username" onChange={(e) => { e.preventDefault(); setData(() => ({ ...data, username: e.target.value })); }} />
          </label>
          <label htmlFor="password">
            Wachtwoord
            <input type="password" id="password" onChange={(e) => setData(() => ({ ...data, password: e.target.value }))} />
          </label>
          {error && <div className="form-error">{error}</div>}

          <div className="button-container">
            <button type="submit">Login</button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default Login;

const apiEndpoint = process.env.REACT_APP_ENDPOINT;

class AuthService {
  async login(username, password) {
    return fetch(
      `${apiEndpoint}/login/`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ username, password }),
      },
    )
      .then((r) => r);
  }
}

export default new AuthService();

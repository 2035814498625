import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import './updateStreets.css';
import ToggleSwitch from '../../components/toggleSwitch/toggleSwitch';
import { BContext } from '../../contexts/boardContext';
import StreetService from '../../services/StreetService';

function UpdateActiveStreets() {
  const { board, boardDataIsLoading, renderBoardData } = useContext(BContext);

  const [streetArray, setStreetArray] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [activeStreets, setActiveStreets] = useState();

  useEffect(() => {
    setLoading(true);
  }, []);

  const countActiveStreets = () => {
    const activeStreetCount = streetArray?.filter((item) => item.active === 1).length;
    if (activeStreetCount < 32) {
      setError(`Let op! Er zijn 32 straten nodig voor een spel. U heeft nu ${activeStreetCount} actieve straten.`);
    } else if (activeStreetCount > 32) {
      setError(`Let op! Er zijn 32 straten nodig voor een spel. U heeft nu ${activeStreetCount} actieve straten.`);
    } else {
      setError('');
    }
    setActiveStreets(activeStreetCount);
  };

  useEffect(() => {
    countActiveStreets();
  }, [streetArray]);

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      setStreetArray(board.streets);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  const updateActiveState = async (street) => {
    const oldState = street.active === 1;

    if (activeStreets < 32 || oldState) {
      const index = streetArray.findIndex((str) => str.id === street.id);
      const newStreetArray = streetArray;
      newStreetArray[index].active = !oldState;
      setStreetArray(newStreetArray);

      await StreetService.updateStreetActive(street.id, !oldState)
        .then(() => renderBoardData())
        .catch((err) => err);
    } else {
      alert('te veel straten, maak er eerst een inactief');
    }
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>Mijn straten</h2>
        <BackButton url="/admin" />
        <div className="table-container">
          {error && <div className="form-error" style={{ padding: '5px', marginBottom: '5px', marginTop: 0 }}>{error}</div>}
          <table>
            <tr>
              <th>Straatnaam</th>
              <th>Actief</th>
            </tr>
            {streetArray?.map((street) => (
              <tr>
                <td>{street.name.replace(/\\n/g, '')}</td>
                <td>
                  <ToggleSwitch
                    id={`street${street.id}`}
                    small
                    checked={street.active}
                    onChange={() => updateActiveState(street)}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default UpdateActiveStreets;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function BackButton({ url }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: 'absolute', top: '10px', left: '10px', color: '#6B7AC9',
      }}
      onClick={() => navigate(url || '/')}
    >
      <p>
        <FontAwesomeIcon style={{ paddingRight: '5px', paddingLeft: '5px' }} icon="fa-solid fa-arrow-left" />
        <strong>Terug </strong>
      </p>
    </div>
  );
}

export default BackButton;

import React, {
  useRef, useState, useEffect, useContext,
} from 'react';
// import { useNavigate } from 'react-router-dom';
import './board.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faX } from '@fortawesome/free-solid-svg-icons';
import fontawesome from '@fortawesome/fontawesome';
import MonopolyMan from '../images/monopoly-man.png';
import Loader from './loader';
import RailRoad from '../images/railroad.png';
// import Chance from '../images/chance.png';
import Start from '../images/start.png';
import FreeParking from '../images/free-parking.png';
import GoToJail from '../images/go-to-jail.png';
import InJail from '../images/in-jail.png';
// import LightBulb from '../images/light-bulb.png';
// import CommunityChest from '../images/comunity-chest.png';
import SwipeAnimation from '../animations/swipeAnimation/swipeAnimation';
// import sold from '../images/stamp-verkocht.png';
import { BContext } from '../contexts/boardContext';
import StreetComponent from './streetComponent';
// import { Context } from '../contexts/userContext';

// Functie om te controleren of een punt zich binnen een element bevindt
function isPointInsideElement(point, element) {
  const rect = element.getBoundingClientRect();
  return (
    point.x > rect.left && point.x < rect.right && point.y > rect.top && point.y < rect.bottom
  );
}

// Functie om de hoek van een punt te berekenen
function getAngle(point) {
  if (point.x < 0 && point.y > 0) {
    return 180 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  if (point.x > 0 && point.y < 0) {
    return 360 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  if (point.x < 0 && point.y < 0) {
    return 180 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  return (Math.atan(point.y / point.x) * 180) / Math.PI;
}

function Boardv2() {
  fontawesome.library.add(faArrowsSpin, faX);
  // const navigate = useNavigate();

  const { board, boardDataIsLoading } = useContext(BContext);
  // const { userdata } = useContext(Context);

  const [rotateGesture, setRotateGesture] = useState(false);
  const rotateBtnRef = useRef(null);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [startAngle, setStartAngle] = useState(0);
  const [loading, setLoading] = useState(false);
  const [streetArray, setStreetArray] = useState();
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [boardFixed, setBoardFixed] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    if (rotateBtnRef.current) {
      const rect = rotateBtnRef?.current.getBoundingClientRect();
      setOrigin({
        x: rect.left - window.scrollX + rect.width / 2,
        y: rect.top - window.scrollY + rect.height / 2,
      });
    }
  }, []);

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      const tempArray = board.streets?.filter((street) => street.active === 1);
      if (tempArray.length !== 32) { setError('Er zijn te veel of niet genoegen straten geselecteerd. Ga naar admin om dit probleem op te lossen'); }
      setStreetArray([...tempArray]);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  if (loading || boardDataIsLoading) {
    return <Loader />;
  }

  const handleRotate = ({ x, y }) => {
    const angle = getAngle({
      x: x - origin.x,
      y: origin.y - y,
    });
    const delta = startAngle - angle;
    setRotation(rotation + delta);
    setStartAngle(angle);
  };

  const handleMove = (x, y) => {
    if (!boardFixed) {
      if (typeof x !== 'undefined' && typeof y !== 'undefined') {
        if (rotateGesture) {
          handleRotate({ x, y });
        } else {
          const insideRotate = isPointInsideElement({ x, y }, rotateBtnRef?.current);
          setRotateGesture(insideRotate);
          if (insideRotate) {
            setStartAngle(getAngle({ x: x - origin.x, y: origin.y - y }));
          }
        }
      }
    }
  };

  const handleMoveMouse = (x, y) => {
    if (!boardFixed) {
      if (mouseIsDown) {
        const insideRotate = isPointInsideElement({ x, y }, rotateBtnRef?.current);
        setRotateGesture(insideRotate);
        if (insideRotate) {
          setStartAngle(getAngle({ x: x - origin.x, y: origin.y - y }));
          handleRotate({ x, y });
        }
      }
    }
  };

  const onMouseMove = (event) => {
    handleMoveMouse(event.clientX, event.clientY);
  };

  const onTouchStart = (event) => {
    const touch = event.touches[0];
    if (touch) {
      handleMove(touch.clientX, touch.clientY);
    }
  };

  const onTouchMove = (event) => {
    const touch = event.touches[0];
    if (touch) {
      handleMove(touch.clientX, touch.clientY);
    }
  };

  const onTouchEnd = () => {
    setRotateGesture(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="home-page-container"
      onMouseDown={() => setMouseIsDown(true)}
      onMouseMove={onMouseMove}
      onMouseUp={() => setMouseIsDown(false)}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {error ? <div>{error}</div> : (
        <>
          {boardFixed ? <FontAwesomeIcon onClick={() => setBoardFixed(!boardFixed)} style={{ position: 'absolute', right: '20px', top: '10px' }} color="#6B7AC9" size="2x" icon="lock" /> : <FontAwesomeIcon onClick={() => setBoardFixed(!boardFixed)} style={{ position: 'absolute', right: '10px', top: '10px' }} color="#6B7AC9" size="2x" icon="lock-open" />}
          {!boardFixed && <SwipeAnimation />}
          {/* {userdata?.username && (
            <div style={{
              position: 'absolute', top: '5px', left: '5px',
              display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
            }}
            >
              <p style={{ padding: 0, margin: 0 }}>Ingelogd als:</p>
              <h2 style={{ padding: 0, margin: 0 }}>{userdata?.username}</h2>
            </div>
          )} */}
          <div style={{ transform: `rotate(${rotation}deg)` }}>
            <div className="responsive board" ref={rotateBtnRef}>
              <div className="mainSquare">
                <div className="row top">
                  <div className="square2">
                    <span className="corner corner1">
                      <img width="50%" className="rotation4" src={FreeParking} alt="free-parking" />
                    </span>

                  </div>
                  <StreetComponent key={streetArray?.[0].id} id={streetArray?.[0].id} headerColor="red" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[1].id} id={streetArray?.[1].id} headerColor="red" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[2].id} id={streetArray?.[2].id} headerColor="red" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[3].id} id={streetArray?.[3].id} headerColor="red" rotationClass="rotation2" direction="top" />
                  <div className="square1">
                    <div className="firstLine firstLine-top no-color rotation2">
                      {/* b. & o.
                      <br />
                      {' '}
                      railroad */}
                    </div>
                    <div className="firstLine firstLine-top no-color rotation2 board-icon-container">
                      <img className="board-icon" src={RailRoad} alt="railroad" />
                    </div>
                  </div>
                  <StreetComponent key={streetArray?.[4].id} id={streetArray?.[4].id} headerColor="yellow" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[5].id} id={streetArray?.[5].id} headerColor="yellow" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[6].id} id={streetArray?.[6].id} headerColor="yellow" rotationClass="rotation2" direction="top" />
                  <StreetComponent key={streetArray?.[7].id} id={streetArray?.[7].id} headerColor="yellow" rotationClass="rotation2" direction="top" />
                  <div className="square2">
                    <span className="corner corner2">
                      <img width="50%" src={GoToJail} alt="go-to-jail" />
                    </span>
                    {/* <span className="corner corner2">go to<br />jail</span> */}
                  </div>
                </div>

                <div className="row center">
                  <div className="square2">
                    <StreetComponent key={streetArray?.[8].id} id={streetArray?.[8].id} headerColor="orange" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[9].id} id={streetArray?.[9].id} headerColor="orange" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[10].id} id={streetArray?.[10].id} headerColor="orange" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[11].id} id={streetArray?.[11].id} headerColor="orange" rotationClass="rotation1" type="side" direction="left" />
                    <div className="squareSide">
                      <div className="firstLine firstLine-left no-color rotation1">
                        {/* pennsylvania
                        <br />
                        {' '}
                        railroad */}
                      </div>
                      <div className="firstLine firstLine-left no-color rotation1 board-icon-container">
                        <img className="board-icon" src={RailRoad} alt="railroad" />
                      </div>
                    </div>
                    <StreetComponent key={streetArray?.[12].id} id={streetArray?.[12].id} headerColor="purple" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[13].id} id={streetArray?.[13].id} headerColor="purple" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[14].id} id={streetArray?.[14].id} headerColor="purple" rotationClass="rotation1" type="side" direction="left" />
                    <StreetComponent key={streetArray?.[15].id} id={streetArray?.[15].id} headerColor="purple" rotationClass="rotation1" type="side" direction="left" />
                  </div>
                  <div className="square9">
                    <img className="monopoly-logo-image" src={MonopolyMan} alt="monopoly-man" />
                    <div className="monopoly-logo">
                      <h2 className="monopoly-logo-text">{board.board_name}</h2>
                    </div>
                  </div>
                  <div className="square2">
                    <StreetComponent key={streetArray?.[16].id} id={streetArray?.[16].id} headerColor="green" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[17].id} id={streetArray?.[17].id} headerColor="green" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[18].id} id={streetArray?.[18].id} headerColor="green" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[19].id} id={streetArray?.[19].id} headerColor="green" rotationClass="rotation3" type="side" direction="right" />
                    <div className="squareSide">
                      <div className="firstLine firstLine-right no-color rotation3">
                        {/* short
                        <br />
                        {' '}
                        line */}
                      </div>
                      <div className="firstLine firstLine-right no-color rotation3 board-icon-container">
                        <img className="board-icon" src={RailRoad} alt="railroad" />
                      </div>
                    </div>
                    <StreetComponent key={streetArray?.[20].id} id={streetArray?.[20].id} headerColor="blue" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[21].id} id={streetArray?.[21].id} headerColor="blue" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[22].id} id={streetArray?.[22].id} headerColor="blue" rotationClass="rotation3" type="side" direction="right" />
                    <StreetComponent key={streetArray?.[23].id} id={streetArray?.[23].id} headerColor="blue" rotationClass="rotation3" type="side" direction="right" />
                  </div>
                </div>

                <div className="row top">
                  <div className="square2">
                    <span className="corner corner4">
                      <img width="75%" src={InJail} alt="in-jail" />
                    </span>
                  </div>
                  <StreetComponent key={streetArray?.[24].id} id={streetArray?.[24].id} headerColor="lightblue" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[25].id} id={streetArray?.[25].id} headerColor="lightblue" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[26].id} id={streetArray?.[26].id} headerColor="lightblue" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[27].id} id={streetArray?.[27].id} headerColor="lightblue" rotationClass="" direction="bottom" />
                  <div className="square1">
                    <div className="firstLine firstLine-bottom no-color">
                      {/* reading
                      <br />
                      {' '}
                      railroad */}
                    </div>
                    <div className="firstLine firstLine-bottom no-color board-icon-container">
                      <img className="board-icon" src={RailRoad} alt="railroad" />
                    </div>
                  </div>
                  <StreetComponent key={streetArray?.[28].id} id={streetArray?.[28].id} headerColor="brown" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[29].id} id={streetArray?.[29].id} headerColor="brown" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[30].id} id={streetArray?.[30].id} headerColor="brown" rotationClass="" direction="bottom" />
                  <StreetComponent key={streetArray?.[31].id} id={streetArray?.[31].id} headerColor="brown" rotationClass="" direction="bottom" />
                  <div className="square2">
                    <span className="corner corner3">
                      <img width="100%" src={Start} alt="start" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Boardv2;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavBar() {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [navBarClosed, setNavBarClosed] = useState(true);

  useEffect(() => {
    if (!navBarClosed) {
      // Voeg een click event listener toe aan het document om buiten de navbar te klikken
      const navbar = document.querySelector('.navbar-container');
      document.addEventListener('click', (event) => {
        const isClickedOutside = !navbar.contains(event.target);

        if (isClickedOutside) {
          setNavBarClosed(true);
        }
      });
    }
  }, [navBarClosed]);

  // fontawesome.library.add(faQrcode, faList);

  return (
    <div className="navbar-container">
      <div className={`navbar-menu-icon ${navBarClosed && 'closed'}`} onClick={() => setNavBarClosed((c) => !c)}>
        <FontAwesomeIcon size="2x" color="#6B7AC9" icon="list" />
      </div>
      <div className={`navbar mobile-only ${navBarClosed && 'closed'}`}>
        <div className="navbar-item" onClick={() => navigate('/admin')}>
          <FontAwesomeIcon size="xl" color="#ffffff" icon="user" />
          <p className="navbar-text">Admin</p>
        </div>
        <div className="navbar-item" onClick={() => navigate('/logging')}>
          <FontAwesomeIcon size="xl" color="#ffffff" icon="list" />
          <p className="navbar-text">Logboek</p>
        </div>
        <div className="navbar-item" onClick={() => navigate('/scan')}>
          <FontAwesomeIcon size="xl" color="#ffffff" icon="qrcode" />
          <p className="navbar-text">Scan qr code</p>
        </div>
        {/* <div className="navbar-item" onClick={() => navigate('/streetList')} >
                <FontAwesomeIcon size="xl" color="#ffffff" icon="list" />
            </div> */}

        <div className="navbar-item" onClick={() => navigate('/')}>
          <FontAwesomeIcon size="xl" color="#ffffff" icon="home" />
          <p className="navbar-text">Home</p>
        </div>

        <div className="navbar-close" onClick={() => setNavBarClosed((c) => !c)}>
          {/* <FontAwesomeIcon size="xl" color="#ffffff" icon="x" /> */}
        </div>
      </div>
    </div>
  );
}

export default NavBar;

import React, { useEffect, useState } from 'react';
import {
  Document,
  Image,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import BoardService from '../services/BoardService';
import Loader from '../components/loader';

// eslint-disable-next-line no-unused-vars
function ProofOfOwnerDoc({ districts, setPdfLoader }) {
  const [streets, setStreets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageArray, setPageArray] = useState();

  useEffect(async () => {
    await BoardService.getQrcodes(1)
      .then((r) => { setStreets(r); setLoading(false); })
      .catch((err) => err);
  }, []);

  const renderPages = () => {
    const pages = [];
    for (let i = 0; i < Math.ceil(streets.length / 2); i += 1) {
      const headerColor = districts.find(
        (d) => d.district_id === streets[i * 2].district_id,
      ).district_hex_color;
      const headerColor2 = districts.find(
        (d) => d.district_id === streets[i * 2 + 1].district_id,
      ).district_hex_color;
      pages.push(
        <Page orientation="landscape" style={{ padding: '5px' }}>
          <View style={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            <View style={{
              height: '100%', width: 'calc((1/2)*100%)', display: 'flex',
            }}
            >
              <View style={{
                backgroundColor: '', padding: '10px', width: '100%', height: '100%', border: '1px solid #000',
              }}
              >
                <View style={{
                  height: '25%', backgroundColor: headerColor, justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <Text style={{
                    color: '#000', fontSize: '30px', fontWeight: '600',
                  }}
                  >
                    {streets[i * 2].name.replace(/\\n/g, '').replace(/\n/g, '')}
                  </Text>
                </View>
                <View style={{
                  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px',
                }}
                >
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Huur-Onbebouwd</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2].rent_basic}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 1 huis</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2].rent_one_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 2 huizen</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2].rent_two_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 3 huizen</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2].rent_three_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Prijs van een huis</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2].cost_house}
                      ,-
                    </Text>
                  </View>

                  <View style={{
                    display: 'flex',
                    width: '100%',
                    borderBottom: '1px solid #000',
                    borderTop: '1px solid #000',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  >
                    <Text style={{ fontSize: '16px', paddingBottom: '5px' }}>
                      Als een groepje ALLE vakjes van één kleurgroep bezit.
                      Wordt de huur voor &apos;onbebouwd&apos; verdubbeld.
                    </Text>
                  </View>

                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Aankoopprijs</Text>
                    <Text style={{ fontSize: '18px', paddingLeft: '5px' }}>
                      €
                      {' '}
                      {streets[i * 2].cost}
                      ,-
                    </Text>
                  </View>
                  <Image style={{ width: '40%', marginTop: '10px' }} src={streets[i * 2].imgUrl} />
                </View>

              </View>
            </View>
            <View style={{
              height: '100%', width: 'calc((1/2)*100%)', display: 'flex',
            }}
            >
              <View style={{
                backgroundColor: '', padding: '10px', width: '100%', height: '100%', border: '1px solid #000',
              }}
              >
                <View style={{
                  height: '25%', backgroundColor: headerColor2, justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <Text style={{
                    color: '#000', fontSize: '30px', fontWeight: '600',
                  }}
                  >
                    {streets[i * 2 + 1].name.replace(/\\n/g, '').replace(/\n/g, '')}
                  </Text>
                </View>
                <View style={{
                  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px',
                }}
                >
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Huur-Onbebouwd</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].rent_basic}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 1 huis</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].rent_one_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 2 huizen</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].rent_two_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  >
                    <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '18px' }}>Met 3 huizen</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].rent_three_house}
                      ,-
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Prijs van een huis</Text>
                    <Text style={{ fontSize: '18px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].cost_house}
                      ,-
                    </Text>
                  </View>

                  <View style={{
                    display: 'flex',
                    width: '100%',
                    borderBottom: '1px solid #000',
                    borderTop: '1px solid #000',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  >
                    <Text style={{ fontSize: '16px', paddingBottom: '5px' }}>
                      Als een groepje ALLE vakjes van één kleurgroep bezit.&#10;
                      Wordt de huur voor &apos;onbebouwd&apos; verdubbeld.
                    </Text>
                  </View>

                  <View style={{
                    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    <Text style={{ fontSize: '18px' }}>Aankoopprijs</Text>
                    <Text style={{ fontSize: '18px', paddingLeft: '5px' }}>
                      €
                      {' '}
                      {streets[i * 2 + 1].cost}
                      ,-
                    </Text>
                  </View>
                  <Image style={{ width: '40%', marginTop: '10px' }} src={streets[i * 2 + 1].imgUrl} />
                </View>

              </View>
            </View>
          </View>
        </Page>,
      );
      setPdfLoader(false);
    }

    setPageArray(pages);
  };

  useEffect(() => {
    if (streets.length > 0) {
      renderPages();
    }
  }, [streets]);

  if (loading) {
    return (<Loader />);
  }
  return (
    <Document>
      {pageArray}
    </Document>
  );
}

export default ProofOfOwnerDoc;

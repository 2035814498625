import React, { useEffect, useState } from 'react';

function Logging() {
  // const { board } = useContext(BContext);
  const [logArray, setLogArray] = useState([]);
  const [expiredLogArray, setExpiredLogArray] = useState([]);
  const [oldLogsOpen, setOldLogsOpen] = useState(false);

  const formatTimeStamp = (timestamp) => {
    const date = new Date(timestamp);
    const timeString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}\n${(`0${date.getUTCHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;

    return timeString;
  };

  const getLogging = async () => {
    await fetch(`https://monopolybackend.onrender.com/api/getAllLogs/?boardId=${1}`)
      .then((r) => r.json())
      .then((r) => {
        const tempArray = [];
        const expiredArray = [];

        r?.reverse().map((item) => {
          if (item.expired === 0) {
            tempArray.push(
              <div className="log-item">
                <p className="date">{formatTimeStamp(item.timestamp)}</p>
                <p>{item.log}</p>
              </div>,
            );
          } else {
            expiredArray.push(
              <div className="log-item" style={{ backgroundColor: '#E3E4E6' }}>
                <p className="date">{formatTimeStamp(item.timestamp)}</p>
                <p>{item.log}</p>
              </div>,
            );
          }
          return item;
        });
        setLogArray(tempArray);
        setExpiredLogArray(expiredArray);
      });
  };

  useEffect(() => {
    getLogging();
  }, []);

  return (
    <div className="street-card-container" style={{ maxHeight: '100vh' }}>
      {/* <BackButton /> */}
      <h2 style={{ paddingTop: '80px', margin: 0, color: '#6B7AC9' }}>Geschiedenis</h2>

      <div style={{
        width: '100%', height: '70%', display: 'flex', overflow: 'auto', alignItems: 'center', flexDirection: 'column', paddingTop: '10px',
      }}
      >
        {logArray}
        {logArray.length < 1
        && (
          <div className="form-error" style={{ padding: '10px', margin: '20px', width: '80%' }}>Er zijn nog geen logs voor dit spel</div>
        )}
        <div
          style={{
            width: '80%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column',
          }}
          onClick={() => setOldLogsOpen(!oldLogsOpen)}
        >
          <p>
            Logs van eerdere spellen
            {oldLogsOpen ? ' ↑' : ' ↓'}
          </p>
          <div style={{ width: '100%', height: '2px', backgroundColor: 'grey' }} />
        </div>
        {oldLogsOpen && expiredLogArray}
      </div>
    </div>
  );
}

export default Logging;

import React, { useEffect } from 'react';
import './swipeAnimation.css';

function SwipeAnimation() {
  useEffect(() => {
    const animatingElement = document.getElementById('hand-x');

    animatingElement?.addEventListener('animationend', () => {
      const element = document.getElementById('swipe-child');
      const container = document.getElementById('swipe-animation');
      container.removeChild(element);
    });
  }, []);

  return (
    <div className="swipe-animation" id="swipe-animation">
      <div
        id="swipe-child"
        style={{
          position: 'absolute', marginTop: '40px', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContext: 'center', zIndex: 999999,
        }}
      >
        <svg id="Swipe-horizontal_2" data-name="Swipe horizontal 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <path className="hand-x" id="hand-x" d="M139.93,102.68,98.81,95.75V65.2A9.25,9.25,0,0,0,89.56,56h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,111.77c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,102.68Z" />
          <g className="swipe-horizontal">
            <path className="line-horizontal" d="M82.15,41.32c19.69-12.46,37,0,37,0" />
            <polyline className="arrow-left" points="87.89 45.32 79.67 42.74 79.67 42.71 82.25 34.49" />
            <polyline className="arrow-right" points="111.5 43.97 119.73 41.39 119.72 41.36 117.14 33.14" />
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SwipeAnimation;

import React, {
  createContext, useEffect, useState,
} from 'react';

export const Context = createContext();

function UserContext(props) {
  const { children } = props;
  const [userdata, setUserdata] = useState({
    username: JSON.parse(localStorage.getItem('userdata'))?.username,
    loggedIn: JSON.parse(localStorage.getItem('userdata'))?.loggedIn,
    admin: JSON.parse(localStorage.getItem('userdata'))?.admin,
  });

  useEffect(() => {
    localStorage.setItem('userdata', JSON.stringify(userdata));
  }, [userdata]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ userdata, setUser: (value) => setUserdata(value) }}>
      {children}
    </Context.Provider>
  );
}

export default UserContext;

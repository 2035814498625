import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sold from '../images/stamp-verkocht.png';
import { BContext } from '../contexts/boardContext';
// import Loader from './loader';

function StreetComponent({
  id, rotationClass = 'rotation2', type = '', direction = 'top',
}) {
  const navigate = useNavigate();
  //   street = getStreet(1);

  const { board, boardDataIsLoading } = useContext(BContext);
  const [loading, setLoading] = useState(true);
  const [street, setStreet] = useState();
  const [district, setDistrict] = useState();
  //   const [hcolor, setHeaderColor] = useState();

  useEffect(() => {
    if (!boardDataIsLoading && board.streets && board.districts && id) {
      const currentStreet = board.streets.find((s) => s.id === id);
      const currentDistrict = board.districts.find(
        (d) => d.district_id === currentStreet.district_id,
      );
      setStreet(currentStreet);
      setDistrict(currentDistrict);
      setLoading(false);
    }
  }, [board, boardDataIsLoading]);

  if (boardDataIsLoading || loading) {
    return (<div />);
  }

  return (
    <div
      className={`${type === 'side' ? 'squareSide' : 'square1'}`}
      id={id}
      onClick={() => navigate(`/street/?streetId=${id}`)}
    >
      <div className={`${type === 'side' ? 'headerSide' : 'header'} header-${direction}`} style={{ backgroundColor: district?.district_hex_color }} />
      <div className={`firstLine firstLine-${direction} ${rotationClass}`}>{street?.name.replace(/\\n/g, '\n')}</div>
      {street?.sold === 1
                      && (
                        <div className={`firstLine firstLine-${direction} ${rotationClass} board-icon-container`}>
                          <img className="board-icon" src={sold} alt="sold" />
                        </div>
                      )}
    </div>
  );
}

export default StreetComponent;

import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import React, { useEffect } from 'react';
import Boardv2 from './components/boardv2';
import StreetCard from './pages/streetCard';
import StreetList from './pages/streetList';
import Admin from './pages/admin/admin';
import NotFound from './pages/notFound';
import BuyStreet from './pages/admin/buyStreet';
import BuyHouse from './pages/admin/buyHouse';
import Logging from './pages/logging';
import ProtectedRoutes from './routes/protectedRoutes';
import Login from './pages/login';
import UserContext from './contexts/userContext';
import BoardContext from './contexts/boardContext';
import ResetBoard from './pages/admin/resetBoard';
import setupInterceptors from './services/Interceptor';
import 'react-toastify/dist/ReactToastify.css';
import logOut from './helpers/authHelpers';
import DownloadStreets from './pages/admin/downloadStreets';
import UpdateActiveStreets from './pages/admin/updateActiveStreets';
import UpdateStreet from './pages/admin/updateStreet';
import NavBar from './components/navbar';
import QrScanner from './pages/qrScanner';
import AddStreet from './pages/admin/addStreet';

function Layout({ children }) {
  return (
    <div className="page-container">
      <NavBar />
      {children}
    </div>
  );
}

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    setupInterceptors(() => logOut(navigate));
  }, []);

  return (
    <div className="App">
      <UserContext>
        <BoardContext>
          <Routes>
            <Route
              path="/"
              element={(
                <Layout>
                  <Boardv2 />
                </Layout>
              )}
            />
            {/* <Route path="/" element={<Boardv2 />} /> */}
            <Route path="/street" element={<StreetCard />} />
            {/* <Route path="/scan" element={<QRScanner />} /> */}
            <Route
              path="/scan"
              element={(
                <Layout>
                  <QrScanner />
                </Layout>
              )}
            />
            <Route path="/streetList" element={<StreetList />} />
            {/* <Route path="/logging" element={<Logging />} /> */}
            <Route
              path="/logging"
              element={(
                <Layout>
                  <Logging />
                </Layout>
              )}
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <Layout>
                    <Admin />
                  </Layout>
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/download-streets"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <DownloadStreets />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/buy-street"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <BuyStreet />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/add-street"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <AddStreet />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/buy-house"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <BuyHouse />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/update-active-streets"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <UpdateActiveStreets />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/update-street"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <UpdateStreet />
                </ProtectedRoutes>
              )}
            />
            <Route
              path="/reset-board"
              element={(
                <ProtectedRoutes>
                  {' '}
                  <ResetBoard />
                </ProtectedRoutes>
              )}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BoardContext>
      </UserContext>
    </div>
  );
}

export default App;

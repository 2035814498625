import React from 'react';
import BackButton from '../components/backButton';

function StreetList() {
  return (
    <div className="street-card-container">
      <BackButton />
      <div className="div">
        <table />
      </div>
    </div>
  );
}

export default StreetList;

import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import { BContext } from '../../contexts/boardContext';
import StreetService from '../../services/StreetService';

function AddStreet() {
//   const navigate = useNavigate();

  const { board, boardDataIsLoading, renderBoardData } = useContext(BContext);

  const [formData, setFormData] = useState({ streetId: '' });
  const [validForm, setValidForm] = useState(false);
  const [streetArray, setStreetArray] = useState();
  const [districtArray, setDistrictArray] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      const filteredArray = board.streets.filter((item) => item.active === 1);
      setStreetArray(filteredArray);
      const { districts } = board;
      setDistrictArray(districts);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  const validateForm = () => {
    if (
      formData.name
      && formData.rent_basic > 0
      && formData.rent_one_house > 0
      && formData.rent_two_house > 0
      && formData.rent_three_house > 0
      && formData.cost_house > 0
      && formData.cost > 0
      && formData.district_id
    ) {
      setLoading(false);
      setError('');
      return true;
    }
    // setError('Alle velden zijn verplicht');
    setLoading(false);
    return false;
  };

  useEffect(() => {
    setValidForm(validateForm());
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      await StreetService.addStreet(formData)
        .then(() => {
          toast.success(`${formData.name} is toegevoegd!`, {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            theme: 'light',
          });
          //   navigate(`/street/?streetId=${formData.streetId}`);
          renderBoardData();
        })
        .catch((err) => err);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleStreetChange = (e) => {
    setFormData(() => ({
      ...formData,
      streetId: e.target.value,
      name: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).name,
      rent_basic: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).rent_basic,
      rent_one_house: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).rent_one_house,
      rent_two_house: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).rent_two_house,
      rent_three_house: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).rent_three_house,
      cost_house: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).cost_house,
      cost: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).cost,
      district_id: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).district_id,
    }));
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="form-container">
        <h2>Straat aanpassen</h2>
        <BackButton url="/admin" />
        <form onSubmit={handleSubmit}>

          {/* <label htmlFor="streetID">Welke straat wilt u aanpassen?</label>
          <select id="streetId" name="streetId" onChange={(e) => handleStreetChange(e)}>
            <option disabled selected value> -- Kies een straat -- </option>
            {streetArray?.map((item) => (
              <option value={item.id}>
                {item.name.replace(/\\n/g, '')}
              </option>
            ))}
          </select> */}

          <label htmlFor="rent_basic">Straatnaam</label>
          <input
            id="name"
            type="text"
            onChange={(e) => setFormData(() => ({ ...formData, name: e.target.value }))}
            value={formData.name}
          />
          <label htmlFor="district">Bij welke wijk moet deze straat horen?</label>
          <select id="district" name="district" value={formData.district_id} onChange={(e) => setFormData({ ...formData, district_id: e.target.value })}>
            {districtArray?.map((item) => (
              <option value={item.district_id}>
                {item.district_name.replace(/\\n/g, '')}
              </option>
            ))}
          </select>
          <label htmlFor="rent_basic">Huur onbebouwd:</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="rent_basic"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({ ...formData, rent_basic: e.target.value }))}
              value={formData.rent_basic}
            />
          </div>
          <label htmlFor="rent_one_house">Huur met 1 huis:</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="rent_one_house"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({
                ...formData,
                rent_one_house: e.target.value,
              }))}
              value={formData.rent_one_house}
            />
          </div>
          <label htmlFor="rent_two_house">Huur met 2 huizen:</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="rent_two_house"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({
                ...formData,
                rent_two_house: e.target.value,
              }))}
              value={formData.rent_two_house}
            />
          </div>
          <label htmlFor="rent_three_house">Huur met 3 huizen:</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="rent_three_house"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({
                ...formData,
                rent_three_house: e.target.value,
              }))}
              value={formData.rent_three_house}
            />
          </div>
          <label htmlFor="cost_house">Prijs van een huis:</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="cost_house"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({
                ...formData,
                cost_house: e.target.value,
              }))}
              value={formData.cost_house}
            />
          </div>
          <label htmlFor="cost">Aankoopprijs</label>
          <div className="input-prefix-box">
            <div className="prefix">€</div>
            <input
              id="cost"
              type="number"
              min={0}
              onChange={(e) => setFormData(() => ({
                ...formData,
                cost: e.target.value,
              }))}
              value={formData.cost}
            />
          </div>
          {error && <div className="form-error"><p>{error}</p></div>}
          <div style={{ marginBottom: '50px' }} className="button-container">
            <button disabled={!validForm} type="submit">Opslaan</button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default AddStreet;

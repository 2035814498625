/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_ENDPOINT;

const setupInterceptors = (logoutFunction) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access-token');
      if (token && !config.url.endsWith('/refresh-token')) {
        // eslint-disable-next-line no-param-reassign
        config.headers['access-token'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem('refresh-token');
          const response = await axios.post(
            `${apiEndpoint}/refresh-token`,
            { refreshToken },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'access-token': refreshToken,
              },
            },
          );
          const token = response.data;

          localStorage.setItem('access-token', token);

          // Retry the original request with the new token
          originalRequest.headers['access-token'] = `Bearer ${token}`;
          return axios(originalRequest);
        } catch (err) {
          // Handle refresh token error or redirect to login
          logoutFunction();
          return Promise.reject(err);
        }
      }

      // if (error.response.status === 401 && originalRequest._retry) {
      //   logoutFunction();
      // }

      return Promise.reject(error);
    },
  );

  // axios.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     if (error.response && error.response.status === 401) {
  //       await logoutFunction();
  //     }
  //     return Promise.reject(error);
  //   },
  // );
};

export default setupInterceptors;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sold from '../images/stamp-verkocht.png';
import Loader from '../components/loader';
import BackButton from '../components/backButton';
import HomeIcon from '../components/homeIcon';
import { BContext } from '../contexts/boardContext';
import StreetService from '../services/StreetService';

function StreetCard() {
  const navigate = useNavigate();

  const { board, boardDataIsLoading } = useContext(BContext);

  const [street, setStreet] = useState();
  const [loading, setLoading] = useState(false);
  const [districtArray, setDistrictArray] = useState();
  const [district, setDistrict] = useState();

  const getDistrict = (str) => {
    const currentDistrict = board.districts?.find((d) => d.district_id === str.district_id);
    setDistrict(currentDistrict);
  };

  const getStreet = async (streetId) => {
    await StreetService.getStreetById(streetId)
      .then((r) => {
        setStreet(r[0]);
        setTimeout(() => { setLoading(false); }, 400);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    setLoading(true);
    const urlSearchString = window.location.search;

    const params = new URLSearchParams(urlSearchString);
    const streetId = params.get('streetId');

    if (!streetId || streetId === 'np id' || streetId === null) {
      navigate('/');
    } else {
      getStreet(streetId);
    }
  }, []);

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      setDistrictArray(board.districts);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  useEffect(() => {
    if (street) {
      getDistrict(street);
    }
  }, [districtArray, street]);

  const getHouses = () => {
    const houses = [];
    for (let i = 0; i < street?.houses; i += 1) {
      houses.push(<HomeIcon width="70px" color="#6B7AC9" />);
    }
    return houses;
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="street-card-c">
        <BackButton />

        <div className="street-card-background">
          {street?.sold === 1 && (
            <img
              src={sold}
              style={{
                position: 'absolute', left: '-40px', top: '-40px', width: '200px',
              }}
              alt="sold"
            />
          )}
          <div style={{ backgroundColor: district?.district_hex_color }} className="street-card-name-container">
            <p className="street-card-name">{street?.name.replace(/\\n/g, '').replace(/\s/g, '')}</p>
            <p className="street-card-district">{district?.district_name}</p>
          </div>
          <div className="street-card-info-container" style={{ backgroundColor: '' }}>
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px' }}>Huur-Onbebouwd</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.rent_basic}
                ,-
              </p>
            </div>
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px', paddingLeft: '20px' }}>Met 1 huis</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.rent_one_house}
                ,-
              </p>
            </div>
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px', paddingLeft: '20px' }}>Met 2 huizen</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.rent_two_house}
                ,-
              </p>
            </div>
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px', paddingLeft: '20px' }}>Met 3 huizen</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.rent_three_house}
                ,-
              </p>
            </div>
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px' }}>Prijs van een huis</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.cost_house}
                ,-
              </p>
            </div>

            <div style={{ marginTop: '10px' }} className="horizontal-line" />
            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <p style={{ margin: '5px', fontSize: '12px' }}>Als een groepje ALLE vakjes van één kleurgroep bezit. Wordt de huur voor &apos;onbebouwd&apos; verdubbeld. </p>
            </div>
            <div style={{ marginBottom: '10px' }} className="horizontal-line" />

            <div style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', paddingTop: '20px',
            }}
            >
              <p style={{ margin: '5px' }}>Aankoopprijs</p>
              <p style={{ margin: '5px' }}>
                €
                {street?.cost}
                ,-
              </p>
            </div>
            {street?.sold === 1
              && (
                <div style={{
                  display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', paddingTop: '5px',
                }}
                >
                  <p style={{ margin: '5px' }}><strong>Eigenaar:</strong></p>
                  <p style={{ margin: '5px' }}><strong>{street?.owner}</strong></p>
                </div>
              )}

          </div>

        </div>
        {street?.sold === 1
          && (
            <div style={{
              width: '70%', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', paddingTop: '10px',
            }}
            >
              {getHouses()}
            </div>
          )}
      </div>

    </div>
  );
}

export default StreetCard;

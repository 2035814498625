import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import BoardService from '../../services/BoardService';
import { BContext } from '../../contexts/boardContext';

function ResetBoard() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);

  const { board, boardDataIsLoading, renderBoardData } = useContext(BContext);

  useEffect(() => {
    setLoading(false);
  });

  const resetBoard = async () => {
    setLoading(true);
    if (password === 'Janneke') {
      setError('');
      await BoardService.resetBoardById(board.board_id)
        .then((r) => {
          if (r.status === 200) {
            return r.data;
          }
          return r.data.then((rs) => { throw rs.error; });
        })
        .then((r) => { renderBoardData(); alert(`${r.msg}. U wordt nu doorgestuurd naar het board.`); navigate('/'); setLoading(false); })
        .catch((err) => { alert(err); setLoading(false); });
    } else {
      setLoading(false);
      setError('Dit wachtwoord is niet correct');
    }
  };

  if (loading || boardDataIsLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="form-container">
        <BackButton url="/admin" />
        <div className="qr-scanner-text-container">
          <h2 style={{ color: '#ffffff' }}>Reset het volledige spel</h2>
          <p>
            Door het resetten van het spel worden alle straten weer op niet verkocht gezet.
            Alle huizen worden van de straten verwijderd
            en alle Logs van het huidige spel worden niet meer getoond.
          </p>
          <p>
            Deze actie kan niet terug gedraaid worden.
            Als je het zeker weet, vul het wachtwoord in en druk dan op RESET.
          </p>
        </div>

        <div className="container">
          <label htmlFor="password">Wachtwoord</label>
          <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
          {error && <div className="form-error"><p>{error}</p></div>}

          <div className="button-container">
            <button type="submit" onClick={(e) => { e.preventDefault(); resetBoard(); }}>RESET</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ResetBoard;

import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoutes({
  redirectPath = '/login',
  children,
}) {
  const token = localStorage.getItem('access-token');

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Navigate to={redirectPath} replace />;
}

export default ProtectedRoutes;

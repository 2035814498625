import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import BoardService from '../services/BoardService';
import Loader from '../components/loader';
import bwIcon from '../images/bw_icon.png';

// eslint-disable-next-line no-unused-vars
function ProofOfRouteDoc({ districts, setPdfLoader }) {
  const [streets, setStreets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageArray, setPageArray] = useState();

  useEffect(async () => {
    await BoardService.getQrcodes(1)
      .then((r) => { setStreets(r); setLoading(false); })
      .catch((err) => err);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const renderElements = (pageId, rowId) => {
    const index = Math.floor(rowId / 2) + (pageId * 4);
    const elementArray = [];
    for (let i = 0; i < 3; i += 1) {
      const element = (
        <View style={{
          height: '100%', width: 'calc((1/3)*100%)', display: 'flex', border: '2px solid #000 ', flexDirection: 'row',
        }}
        >
          <View style={{
            display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100%', alignItems: 'center',
          }}
          >
            <Image style={{ width: '20%' }} src={streets[index].imgUrl} />
            <Text>{streets[index].name.replace(/\\n/g, '').replace(/\n/g, '')}</Text>
            <Image style={{ height: '80%' }} src={bwIcon} />
          </View>
        </View>
      );
      elementArray.push(element);
    }

    return elementArray;
  };

  const renderRows = (pageId) => {
    const rowsArray = [];
    for (let i = 0; i < 8; i += 1) {
      const element = (
        <View style={{
          height: '100%', width: '100%', display: 'flex', flexDirection: 'row',
        }}
        >
          {renderElements(pageId, i)}
        </View>
      );
      rowsArray.push(element);
    }

    return (rowsArray);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 0; i < Math.ceil(streets.length / 4); i += 1) {
      const element = (
        <Page orientation="landscape" style={{ padding: '5px' }}>
          <View style={{
            width: '100%', height: '100%', display: 'flex',
          }}
          >
            {renderRows(i)}
          </View>

        </Page>
      );
      pages.push(element);
      setPdfLoader(false);
    }

    setPageArray(pages);
  };

  useEffect(() => {
    if (streets.length > 0) {
      renderPages();
    }
  }, [streets]);

  if (loading) {
    return (<Loader />);
  }
  return (
    <Document>
      {pageArray}
    </Document>
  );
}

export default ProofOfRouteDoc;

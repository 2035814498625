import React from 'react';
import './loader.css';

function Loader() {
  return (
    <div className="book-body">
      <div className="loader book">
        <div className="loader book__pg-shadow" />
        <div className="loader book__pg" />
        <div className="loader book__pg book__pg--2" />
        <div className="loader book__pg book__pg--3" />
        <div className="loader book__pg book__pg--4" />
        <div className="loader book__pg book__pg--5" />
      </div>
    </div>
  );
}

export default Loader;

import React, {
  createContext, useEffect, useState,
} from 'react';
import StreetService from '../services/StreetService';
import BoardService from '../services/BoardService';

export const BContext = createContext();

function BoardContext(props) {
  const { children } = props;
  const [board, setBoard] = useState({});
  const [loading, setLoading] = useState(false);

  const getDistrictData = async (currentBoard) => {
    await BoardService.getAllDistricts(currentBoard.board_id)
      .then((r) => {
        setBoard((prevBoard) => ({ ...prevBoard, districts: r.data }));
        setLoading(false);
      })
      .catch((err) => err);
  };

  const getStreetData = async (currentBoard) => {
    await StreetService.getAllStreets(currentBoard.board_id)
      .then((r) => {
        setBoard((prevBoard) => ({ ...prevBoard, streets: r }));
        getDistrictData(currentBoard);
      })
      .catch((err) => err);
  };

  const getBoardDataById = async (boardId) => {
    await BoardService.getBoardById(boardId)
      .then((r) => {
        setBoard(r[0]);
        getStreetData(r[0]);
      })
      .catch((err) => err);
  };

  const renderBoardData = () => {
    setLoading(true);
    getBoardDataById(board.board_id);
  };

  useEffect(() => {
    setLoading(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const boardId = queryParameters.get('boardId');
    if (boardId) {
      getBoardDataById(boardId);
    } else {
      getBoardDataById(1);
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BContext.Provider value={{
      board, setBoard, boardDataIsLoading: loading, renderBoardData,
    }}
    >
      {children}
    </BContext.Provider>
  );
}

export default BoardContext;

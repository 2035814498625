import { toast } from 'react-toastify';

const message = (msg) => toast.error(msg, {
  position: 'top-center',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light',
});

const logOut = async (navigate, msg = 'Je bent uitgelogd vanwege inactiviteit. Log alsjeblieft in en probeer het opnieuw.') => {
  localStorage.removeItem('userdata');
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');

  message(msg);
  await navigate('/login');
  return 'redirect';
};

export default logOut;

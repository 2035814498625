import React, { useEffect, useState } from 'react';
import {
  Document, Image, Page, Text,
  View,
} from '@react-pdf/renderer';
import BoardService from '../services/BoardService';

function QrDoc({ setPdfLoader, districts }) {
  const [dataArray, setDataArray] = useState([]);
  const [firstRowArray, setFirstRowArray] = useState([]);
  const [secondRowArray, setSecondRowArray] = useState([]);
  const [thirdRowArray, setThirdRowArray] = useState([]);
  const [fourthRowArray, setFourthRowArray] = useState([]);
  const [fifthRowArray, setFifthRowArray] = useState([]);
  const [sixthRowArray, setSixthRowArray] = useState([]);
  const [seventhRowArray, setSeventhRowArray] = useState([]);
  const [eighthRowArray, setEighthRowArray] = useState([]);

  useEffect(async () => {
    setPdfLoader(true);
    await BoardService.getQrcodes(1).then((r) => setDataArray(r)).catch((err) => err);
  }, []);

  const renderPages = async () => {
    const firstRow = [];
    const secondRow = [];
    const thirdRow = [];
    const fourthRow = [];
    const fifthRow = [];
    const sixthRow = [];
    const seventhRow = [];
    const eighthRow = [];
    await Promise.all(dataArray.map((el, index) => {
      const headerColor = districts?.find(
        (d) => d.district_id === el.district_id,
      ).district_hex_color;

      const element = (
        <View style={{
          backgroundColor: '', padding: '10px', width: '100%', height: '100%', border: '1px solid #000',
        }}
        >
          <View style={{
            height: '25%', backgroundColor: headerColor, justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Text style={{
              color: '#000', fontSize: '18px', fontWeight: '600',
            }}
            >
              {el.name.replace(/\\n/g, '').replace(/\n/g, '')}
            </Text>
          </View>
          <View style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px',
          }}
          >
            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
            }}
            >
              <Text style={{ fontSize: '10px' }}>Huur-Onbebouwd</Text>
              <Text style={{ fontSize: '10px' }}>
                €
                {' '}
                {el?.rent_basic}
                ,-
              </Text>
            </View>
            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '10px' }}>Met 1 huis</Text>
              <Text style={{ fontSize: '10px' }}>
                €
                {' '}
                {el?.rent_one_house}
                ,-
              </Text>
            </View>
            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '10px' }}>Met 2 huizen</Text>
              <Text style={{ fontSize: '10px' }}>
                €
                {' '}
                {el?.rent_two_house}
                ,-
              </Text>
            </View>
            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
            }}
            >
              <Text style={{ paddingLeft: '10px', marginTop: '2px', fontSize: '10px' }}>Met 3 huizen</Text>
              <Text style={{ fontSize: '10px' }}>
                €
                {' '}
                {el?.rent_three_house}
                ,-
              </Text>
            </View>
            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px',
            }}
            >
              <Text style={{ fontSize: '10px' }}>Prijs van een huis</Text>
              <Text style={{ fontSize: '10px' }}>
                €
                {' '}
                {el?.cost_house}
                ,-
              </Text>
            </View>

            <View style={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid #000',
              borderTop: '1px solid #000',
              paddingTop: '5px',
              paddingBottom: '5px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
            >
              <Text style={{ fontSize: '8px', paddingBottom: '5px' }}>Als een groepje ALLE vakjes van één kleurgroep bezit. Wordt de huur voor &apos;onbebouwd&apos; verdubbeld.</Text>
            </View>

            <View style={{
              display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Text style={{ fontSize: '10px' }}>Aankoopprijs</Text>
              <Text style={{ fontSize: '10px', paddingLeft: '5px' }}>
                €
                {' '}
                {el?.cost}
                ,-
              </Text>
            </View>
            <Image style={{ width: '35%', height: 'auto', marginTop: '5px' }} src={el.imgUrl} />
          </View>

        </View>
      );
      if (index >= 0 && index <= 3) {
        firstRow.push(element);
      } else if (index >= 4 && index <= 7) {
        secondRow.push(element);
      } else if (index >= 8 && index <= 11) {
        thirdRow.push(element);
      } else if (index >= 12 && index <= 15) {
        fourthRow.push(element);
      } else if (index >= 16 && index <= 19) {
        fifthRow.push(element);
      } else if (index >= 20 && index <= 23) {
        sixthRow.push(element);
      } else if (index >= 24 && index <= 27) {
        seventhRow.push(element);
      } else if (index >= 28 && index <= 31) {
        eighthRow.push(element);
      }
      return <div />;
    }));
    setFirstRowArray(firstRow);
    setSecondRowArray(secondRow);
    setThirdRowArray(thirdRow);
    setFourthRowArray(fourthRow);
    setFifthRowArray(fifthRow);
    setSixthRowArray(sixthRow);
    setSeventhRowArray(seventhRow);
    setEighthRowArray(eighthRow);
    if (dataArray.length > 0) { setPdfLoader(false); }
  };

  useEffect(() => {
    renderPages();
  }, [dataArray]);

  return (
    <Document>
      <Page orientation="landscape" style={{ padding: '5px' }}>
        <View style={{
          width: '100%', height: '100%',
        }}
        >
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {firstRowArray}
          </View>
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {secondRowArray}
          </View>
        </View>
      </Page>
      <Page orientation="landscape" style={{ padding: '5px' }}>
        <View style={{
          width: '100%', height: '100%',
        }}
        >
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {thirdRowArray}
          </View>
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {fourthRowArray}
          </View>
        </View>
      </Page>
      <Page orientation="landscape" style={{ padding: '5px' }}>
        <View style={{
          width: '100%', height: '100%',
        }}
        >
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {fifthRowArray}
          </View>
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {sixthRowArray}
          </View>
        </View>
      </Page>
      <Page orientation="landscape" style={{ padding: '5px' }}>
        <View style={{
          width: '100%', height: '100%',
        }}
        >
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {seventhRowArray}
          </View>
          <View style={{
            height: 'calc((1/2)*100%)', width: '100%', display: 'flex', flexDirection: 'row',
          }}
          >
            {eighthRowArray}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default QrDoc;

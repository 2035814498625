import React from 'react';
import './homeIcon.css';

function HomeIcon({
  width = '48px', color = 'red', backgroundColor = '#D5E0FF', onClick = () => { },
}) {
  return (
    <div className="icon-container">
      <div className="icon-home" style={{ width, height: width }} onClick={onClick}>
        <div className="home-body" style={{ backgroundColor: color }} />
        <div className="home-roof-cut-left" style={{ backgroundColor }} />
        <div className="home-roof-cut-right" style={{ backgroundColor }} />
        <div className="home-door" />
        <div className="home-chimney" style={{ backgroundColor: color }} />
        <div className="home-roof-container">
          <div className="home-roof-left" style={{ backgroundColor: color }} />
          <div className="home-roof-right" style={{ backgroundColor: color }} />
        </div>
      </div>
    </div>
  );
}

export default HomeIcon;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import BackButton from '../../components/backButton';
import { BContext } from '../../contexts/boardContext';
import StreetService from '../../services/StreetService';

function BuyStreet() {
  const navigate = useNavigate();

  const { board, boardDataIsLoading, renderBoardData } = useContext(BContext);

  const [formData, setFormData] = useState({ streetId: '', owner: '', sold: '' });
  const [streetArray, setStreetArray] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!boardDataIsLoading && board.board_id) {
      const filteredArray = board.streets.filter((item) => item.active === 1);
      setStreetArray(filteredArray);
      setLoading(false);
    }
  }, [boardDataIsLoading]);

  const validateForm = () => {
    if (formData.sold && formData.owner.length < 1) {
      setError('Als de straat verkocht is moet er een eigenaar zijn ingevuld');
      setLoading(false);
      return false;
    } if (formData.streetId < 1) {
      setError('Straat is verplicht');
      setLoading(false);
      return false;
    }
    setLoading(false);
    setError('');
    return true;
  };

  const handleOwnerChange = () => {
    toast.success(`${streetArray?.find((item) => `${item.id}` === `${formData.streetId}`).name} is verkocht aan ${formData.owner}!`, {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: 'light',
    });
    renderBoardData();
    navigate(`/street/?streetId=${formData.streetId}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      await StreetService.updateStreetSold(formData.owner, formData.sold, formData.streetId)
        .then(() => { handleOwnerChange(); })
        .catch((err) => err);
    }
  };

  const handleStreetChange = (e) => {
    setFormData(() => ({
      ...formData,
      streetId: e.target.value,
      sold: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).sold,
      owner: streetArray?.find((item) => `${item.id}` === `${e.target.value}`).owner,
    }));
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="street-card-container">
      <div className="form-container">
        <h2>Verkoop van een straat</h2>
        <BackButton url="/admin" />
        <form onSubmit={handleSubmit}>

          <label htmlFor="streetID">Welke straat wordt verkocht?</label>
          <select id="streetId" name="streetId" onChange={(e) => handleStreetChange(e)}>
            <option disabled selected value> -- Kies een straat -- </option>
            {streetArray?.map((item) => (
              <option value={item.id}>
                {item.name.replace(/\\n/g, '')}
              </option>
            ))}
          </select>

          <label htmlFor="sold">Is de straat verkocht?</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" id="sold" checked={formData.sold} onChange={(e) => setFormData(() => ({ ...formData, sold: e.target.checked }))} />
            <p style={{ paddingLeft: '10px' }}>Ja</p>
          </div>

          <label htmlFor="owner">Wie wordt de eigenaar van deze straat?</label>
          <select id="owner" value={formData.owner} onChange={(e) => setFormData(() => ({ ...formData, owner: e.target.value }))}>
            <option selected value=""> -- Kies een eigenaar -- </option>
            <option value="Menno">Menno</option>
            <option value="Fernando">Fernando</option>
            <option value="Dylan">Dylan</option>
            <option value="Kristel">Kristel</option>
            <option value="Carlijn">Carlijn</option>
            <option value="Maya">Maya</option>
            <option value="Matthijn">Matthijn</option>
            <option value="Michael">Michael</option>
            <option value="Jan">Jan</option>
            <option value="Dominique">Dominique</option>
          </select>
          {error && <div className="form-error"><p>{error}</p></div>}
          <div className="button-container">
            <button type="submit">Opslaan</button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default BuyStreet;
